.container {
  /* Calendly limits */
  max-width: 800px;
}
.iframe {
  @apply w-full h-full relative z-10;
}

.loader {
  @apply absolute top-1/4 left-1/2 -ml-6 -mt-6 z-0;
}

:root .container__small {
  @apply max-w-lg;
  min-height: 21rem;
  height: fit-content;
  @apply bg-white rounded-lg;
}

.scheduledEvent {
  @apply text-black p-10;
  & h1,
  & h2,
  & p {
    @apply text-black;
  }

  & h1 {
    font-size: 1.85rem;
    @apply mx-auto my-1;
    @apply flex flex-row gap-3 items-center justify-center;
  }
  & h2 {
    font-size: 1rem;
    @apply my-1;
  }
  & p {
    font-size: 1rem;
    @apply my-1;
  }
}

.app_logo {
  @apply w-16 block mx-auto mt-6 my-8;
}
.icon {
  @apply w-6 h-6;
}
.eventDetails {
  max-width: fit-content;
  background-color: #f8f8f8;
  @apply rounded-sm px-5 py-2.5 mt-5;
}
