.sidebarHeader {
  * + & {
    @apply mt-16;
  }
}

.sidebarLinks {
}

.sidebarLink {
  & + .sidebarLink {
    @apply mt-2.5;
  }
  a {
    @apply underline;
    text-decoration-color: rgba(255, 255, 255, 0.5);
    text-decoration-thickness: 0.025em;
    text-underline-offset: 0.2em;

    &:hover {
      @apply underline;
      text-decoration-color: rgba(255, 255, 255, 1);
      text-decoration-thickness: 0.025em;
      text-underline-offset: 0.2em;
    }
  }
}
