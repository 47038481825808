.bodyWithVideo {
  @apply overflow-hidden;
}

.backdrop {
  @apply fixed top-0 left-0 right-0 bottom-0 z-50;
  @apply flex justify-center items-center;
  @apply bg-black bg-opacity-10;
  backdrop-filter: blur(3px);
}

.wrapper {
  @apply w-full h-full p-16;
  @apply flex justify-center items-center;
}

.container {
  @apply relative w-full h-full;
}

.closeButton {
  @apply absolute left-full bottom-full z-40;
  @apply p-1 m-0;
  @apply w-8 h-8;
  @apply rounded-full;
  @apply border-transparent bg-white text-black;
  @apply hover:bg-seljukBlue hover:text-white;
  @apply flex items-center justify-center;
  svg {
    @apply w-6 h-6;
  }
}
