.wrapper {
  @apply relative;
  @apply overflow-x-hidden;
  z-index: 0;
  background-color: var(--website-dark-bg);
}

.container {
  @apply mx-auto;
  max-width: 81rem;
  @apply px-8;

  @screen sm {
    @apply px-10;
  }
  @screen md {
    @apply px-12;
  }
  @screen lg {
    @apply px-16;
  }
}
.wrapper__paddingTop .container {
  @apply pt-24;
  @screen sm {
    @apply pt-12;
  }
  @screen md {
    @apply pt-16;
  }
  @screen lg {
    @apply pt-32;
  }
}
.wrapper__paddingBottom .container {
  @apply pb-24;
  @screen sm {
    @apply pb-12;
  }
  @screen md {
    @apply pb-16;
  }
  @screen lg {
    @apply pb-32;
  }
}
