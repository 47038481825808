.backdrop {
  @apply bg-black bg-opacity-95;
}
.container {
  @apply h-full w-full;
  @apply flex justify-center items-center;
}
.image {
  @apply max-w-full max-h-full;
}

.zoomLink:has(figure > img) {
  @apply relative;
  &::before {
    content: '';
    @apply absolute top-4 right-4 z-20;
    @apply w-6 h-6 bg-contain;
    background-image: url(./zoom-icon.svg);
  }
  &::after {
    content: '';
    @apply absolute top-0 right-0 z-10;
    @apply w-14 h-14;
    @apply bg-black bg-opacity-5;
    @apply rounded-tr-3xl rounded-bl-3xl;
  }
  &:hover::after {
    @apply bg-opacity-20;
  }
}
