ul.toc {
  @apply flex flex-col gap-3;

  li > a {
    @apply flex items-baseline;
    @apply m-0 pl-0;
    @apply text-sm;
    line-height: 1.4rem;
    @apply no-underline;
    @apply transition-colors duration-100;
    @apply text-white text-opacity-80;

    strong,
    em,
    i,
    b {
      @apply font-normal not-italic;
    }

    &:hover {
      @apply text-white;
      @apply no-underline;
    }

    &[aria-selected='true'] {
      @apply text-opacity-100;
      @apply font-medium;
    }
  }

  li > a::before {
    content: '';
    @apply flex-shrink-0 flex-grow-0;
    @apply transition-all duration-300;
    @apply inline-block bg-white rounded-full;
    @apply w-2 h-2 mr-2;
    @apply opacity-0 -ml-4;
    transform: translateY(-0.15rem);
  }
  li > a[aria-selected='true']::before {
    @apply opacity-100;
  }
  li[data-level='h3'] > a::before {
    @apply mr-5;
  }
  li[data-level='h4'] > a::before {
    @apply mr-8;
  }
}
