.quoteText {
  @apply border;
  @apply rounded-xl;
  @apply overflow-hidden;
  @apply mt-8 mb-12;
  background-color: #1a1b3b;
  border-color: #283469;
}

.titleWrapper {
  background-color: #283469;
  @apply px-6 py-2;

  .title {
    @apply font-medium m-0 p-0;
  }

  & > h2,
  & h3,
  & h4,
  & h5 {
    @apply m-0;
    @apply text-base;
  }
}

.quoteBody {
  @apply px-6 py-4;

  > h2,
  h3,
  h4,
  h5 {
    @apply text-base;
    @apply mt-8 mb-5;
    &:first-child {
      @apply mt-5;
    }
  }
}
