.footerWrapper {
  @apply relative;
  @apply -mt-4 border-t;
  border-color: #343953;
  background-color: #0b0f26;
}

.footerLogo {
  width: 33px;
  fill: #fff;
}

.footer {
  background-color: #0d0f24;
  @apply w-full;
}
.footerContainer {
  @apply flex flex-col items-stretch gap-4;
  @apply pt-12 pb-10;
  @apply text-white;
  @screen md {
    @apply gap-2;
  }
}

.footerTop {
  @apply flex flex-col gap-2;
  @screen sm {
    @apply flex-row items-center;
  }
}

.copyright {
  @apply flex flex-row;
}

.navWrapper {
  @apply mt-8 mb-10;
  @apply flex flex-col gap-5;

  @screen md {
    @apply flex-row gap-10;
    @apply mb-16;
  }
}

.groupHead {
  @apply mb-2.5;
  @apply font-medium;
  @apply opacity-40;

  @screen md {
    @apply mb-5;
  }
}

.navConnectors {
  @screen md {
    @apply ml-10;
  }

  @screen lg {
    @apply ml-20;
    flex-basis: 60%;
  }
}

.nav {
  @apply list-none;
  @apply flex flex-col flex-wrap gap-y-3;
}

.link {
  @apply no-underline whitespace-nowrap;
  @apply pb-0.5;
  @apply border-b border-white border-opacity-0;
  &:hover {
    @apply no-underline;
  }
  &:hover:not(.link__active) {
    @apply no-underline;
    @apply border-opacity-30;
  }
}
.link__active {
  @apply border-opacity-100;
}

.linkIcon {
  @apply flex flex-nowrap gap-2;
  @apply text-white;
}

.connectorsNav {
  @apply flex flex-row flex-wrap gap-3;

  > li {
    @apply mb-2;
  }
}

.connectorsLink {
  @apply px-4 py-1;
  @apply rounded-full;
  @apply bg-midnightLight;
  @apply whitespace-nowrap;
}

.footerBottom {
  @apply flex flex-col gap-3;
  color: #b7b8bf;

  @screen md {
    @apply flex-row justify-between;
  }
}

.legalLinks {
  @apply flex flex-col gap-2;

  @screen lg {
    @apply flex-row gap-6;
  }
}
