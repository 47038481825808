.bg {
  @apply pb-16;
  @apply bg-no-repeat bg-right-top;
  background-color: var(--website-dark-bg);
  background-image: url('/images/bg-1.svg');
  background-size: 60% auto;
  background-position: 60% 10vh;

  overflow: initial !important;
}
