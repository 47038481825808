.bannerContainer {
  background: linear-gradient(to bottom, #4a8ef2 0%, #6c57f2 85%);
}
.banner {
  @apply text-center;
  padding-top: 1.25rem;
  padding-bottom: 1.125rem;
  text-wrap: balance;

  & a {
    @apply border-b border-transparent bg-opacity-60;
    &:hover {
      @apply no-underline border-white;
    }
  }
}
